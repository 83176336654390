import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { MuiThemeProvider, createTheme } from '@material-ui/core/styles';

import Header from './header'
import Navbar from './Navbar'
import Users from './Users'
import UserCreate from './UserCreate'
import UserUpdate from './UserUpdate'
import Opportunities from './Opportunities.js'
import OppUpdate from './OpportunityUpdate.js'
import OpportunityCreate from './OpportunityCreate.js'
import Partners from './Partners'
import PartnerUpdate from './PartnerUpdate.js'
import PartnerCreate from './PartnerCreate.js'


const theme = createTheme({
  palette: {
    primary: {
      main: '#00c2cb'
    },
    secondary: {
          main: '#0000cc'
    }
  }
});

export default function App() {
  return (
  <MuiThemeProvider theme={theme}>
    <Router>
      <div>
      <Header />
        <Routes>
          <Route exact path='/' element={<Navbar/>} />
          <Route exact path='/create' element={<UserCreate/>} />
          <Route exact path='/partner' element={<Partners/>} />
          <Route exact path='/partner/create' element={<PartnerCreate/>} />
          <Route exact path='/partner/update/:id' element={<PartnerUpdate/>} />
          <Route exact path='/opportunity' element={<Opportunities/>} />
          <Route exact path='/opportunity/create' element={<OpportunityCreate/>} />
          <Route exact path='/opportunity/update/:id' element={<OppUpdate/>} />
        </Routes>
      </div>
    </Router>
  </MuiThemeProvider>
  );
}