import React, { useState, useEffect } from "react";
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Moment from 'react-moment';
import moment from 'moment';
import Container from '@material-ui/core/Container';
import { useParams } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

export default function OpportunityCreate() {
  const classes = useStyles();

  const handleSubmit = event => {
   event.preventDefault();
      var data = {
        'opportunityCode': code,
        'partnerId': partnerId,
        'opportunityTitle': title,
        'opportunityDesc': short_desc,
        'opportunityTagsCsv': tags,
        'assetClass': assetClass,
        'currency': 'INR',
        'category': category,
        'priorityOrder': priorityOrder,
        'topAttributes':topAttributes,
        'oppUrl': oppUrl,
        'state' : oppState,
        'keyAttributes' : {
                    'MIN_INVEST' : minInvest,
                    'RETURN_RATE' : returnRate,
                    'TENURE' : tenure,
                    'MARKET_CAP' : marketCap,
                    'TOTAL_FUND' : totalFunds,
                    'CURRENT_PROGRESS' : currentProgress,
                    'LIST_DATE' : listDate,
                    'HIGHLIGHTS' : highlights,
                    'LONG_DESC' : longDesc
                },
      }
      fetch('https://api.salter.in/opportunity/', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      })
      .then(res => res.json())
      .then(
        (result) => {
           alert('Creation Successful');
           window.location.href = '/';
        },
        (error) => {
         alert('Failed to create the opportunity, please contact Admin');
         window.location.href = '/';
        }
      )
    }

  const [code, setCode] = useState('');
  const [partnerId, setPartner] = useState('');
  const [title, setTitle] = useState('');
  const [short_desc, setDesc] = useState('');
  const [tags, setTags] = useState('');
  const [assetClass, setAssetClass] = useState('');
  const [category, setCategory] = useState('');
  const [oppState, setOppState] = useState('');
  const [minInvest, setMinInvest] = useState('');
  const [returnRate, setReturnRate] = useState('');
  const [tenure, setTenure] = useState('');
  const [marketCap, setMarketCap] = useState('');
  const [totalFunds, setTotalFunds] = useState('');
  const [currentProgress, setCurrentProgress] = useState('');
  const [listDate, setListDate] = useState('');
  const [highlights, setHighlights] = useState('');
  const [longDesc, setLongDesc] = useState('');
  const [topAttributes, setTopAttributes] = useState('');
  const [createdOn, setCreatedOn] = useState('');
  const [lastUpdatedOn, setLastUpdatedOn] = useState('');
  const [oppUrl, setOppUrl] = useState('');
  const [priorityOrder, setPriorityOrder] = useState('');


 return (
     <Container maxWidth="md">
       <div className={classes.paper}>
         <Typography component="h1" variant="h5">
           Create New Opportunity
         </Typography>
         <form className={classes.form} onSubmit={handleSubmit}>
           <Grid container spacing={2}>
           <Grid item xs={4}>
               <TextField
                 autoComplete="code"
                 name="code"
                 variant="outlined"
                 required
                 fullWidth
                 id="code"
                 label="code"
                 value={code}
                 onChange={(e) => setCode(e.target.value)}
                 autoFocus
               />
             </Grid>
           <Grid item xs={4}>
               <TextField
                 autoComplete="title"
                 name="title"
                 variant="outlined"
                 required
                 fullWidth
                 id="Title"
                 label="Title"
                 value={title}
                 onChange={(e) => setTitle(e.target.value)}
                 
               />
             </Grid>
             <Grid item xs={4}>
               <TextField
                 autoComplete="partnerId"
                 name="partnerId"
                 variant="outlined"
                 required
                 fullWidth
                 id="partnerId"
                 label="partnerId"
                 value={partnerId}
                 onChange={(e) => setPartner(e.target.value)}
               />
             </Grid>
             <Grid item xs={4}>
               <TextField
                 autoComplete="assetClass"
                 name="assetClass"
                 variant="outlined"
                 required
                 fullWidth
                 id="assetClass"
                 label="assetClass"
                 value={assetClass}
                 onChange={(e) => setAssetClass(e.target.value)}
                 
               />
             </Grid>
             <Grid item xs={4}>
               <TextField
                 autoComplete="category"
                 name="category"
                 variant="outlined"
                 required
                 fullWidth
                 id="category"
                 label="category"
                 value={category}
                 onChange={(e) => setCategory(e.target.value)}
                 
               />
             </Grid>
             <Grid item xs={4}>
               <TextField
                 autoComplete="tags"
                 name="tags"
                 variant="outlined"
                 fullWidth
                 id="tags"
                 label="tags"
                 value={tags}
                 onChange={(e) => setTags(e.target.value)}
                 
               />
             </Grid>
             <Grid item xs={8}>
               <TextField
                 autoComplete="short_desc"
                 name="short_desc"
                 variant="outlined"
                 required
                 fullWidth
                 id="short_desc"
                 label="short_desc"
                 value={short_desc}
                 onChange={(e) => setDesc(e.target.value)}
                 
               />
             </Grid>
             <Grid item xs={4}>
               <TextField
                 autoComplete="state"
                 name="state"
                 variant="outlined"
                 required
                 fullWidth
                 id="state"
                 label="state"
                 value={oppState}
                 onChange={(e) => setOppState(e.target.value)}
                 
               />
             </Grid>
             <Grid item xs={12}>
             <Typography component="h5" variant="h5">
                 Key Attributes
              </Typography>
             </Grid>
             <Grid item xs={12}>
               <TextField
                 autoComplete="topAttributes"
                 name="topAttributes"
                 variant="outlined"
                 fullWidth
                 id="topAttributes"
                 label="Top 3 attributes to be shown on browse page (comma separated)"
                 value={topAttributes}
                 onChange={(e) => setTopAttributes(e.target.value)}
                 
               />
             </Grid>
             <Grid item xs={12}>
               <TextField
                 autoComplete="oppUrl"
                 name="oppUrl"
                 variant="outlined"
                 fullWidth
                 id="oppUrl"
                 label="Opportunity URL"
                 value={oppUrl}
                 onChange={(e) => setOppUrl(e.target.value)}
               />
             </Grid>
             <Grid item xs={3}>
               <TextField
                 autoComplete="priorityOrder"
                 name="minInvest"
                 variant="outlined"
                 fullWidth
                 id="priorityOrder"
                 label="Priority Order (1-100) "
                 value={priorityOrder}
                 onChange={(e) => setPriorityOrder(e.target.value)}
               />
             </Grid>
             <Grid item xs={3}>
               <TextField
                 autoComplete="minInvest"
                 name="minInvest"
                 variant="outlined"
                 fullWidth
                 id="minInvest"
                 label="Minimum Investment "
                 value={minInvest}
                 onChange={(e) => setMinInvest(e.target.value)}
                 
               />
             </Grid>
             <Grid item xs={3}>
               <TextField
                 autoComplete="returnRate"
                 name="returnRate"
                 variant="outlined"
                 fullWidth
                 id="returnRate"
                 label="Return Rate"
                 value={returnRate}
                 onChange={(e) => setReturnRate(e.target.value)}
                 
               />
             </Grid>
             <Grid item xs={3}>
               <TextField
                 autoComplete="tenure"
                 name="tenure"
                 variant="outlined"
                 fullWidth
                 id="tenure"
                 label="Tenure in months"
                 value={tenure}
                 onChange={(e) => setTenure(e.target.value)}
                 
               />
             </Grid>
             <Grid item xs={3}>
               <TextField
                 autoComplete="marketCap"
                 name="marketCap"
                 variant="outlined"
                 fullWidth
                 id="marketCap"
                 label="Market Cap"
                 value={marketCap}
                 onChange={(e) => setMarketCap(e.target.value)}
                 
               />
             </Grid>
             <Grid item xs={3}>
               <TextField
                 autoComplete="totalFunds"
                 name="totalFunds"
                 variant="outlined"
                 fullWidth
                 id="totalFunds"
                 label="Total funds Size"
                 value={totalFunds}
                 onChange={(e) => setTotalFunds(e.target.value)}
                 
               />
             </Grid>
             <Grid item xs={3}>
               <TextField
                 autoComplete="currentProgress"
                 name="currentProgress"
                 variant="outlined"
                 fullWidth
                 id="currentProgress"
                 label="Current Progress in %"
                 value={currentProgress}
                 onChange={(e) => setCurrentProgress(e.target.value)}
                 
               />
             </Grid>
             <Grid item xs={3}>
               <TextField
                 autoComplete="listDate"
                 name="listDate"
                 variant="outlined"
                 fullWidth
                 id="listDate"
                 label="List date in DD/MM/YYYY format"
                 value={listDate}
                 onChange={(e) => setListDate(e.target.value)}
                 
               />
             </Grid>
             <Grid item xs={12}>
               <TextField
                 autoComplete="highlights"
                 name="highlights"
                 variant="outlined"
                 fullWidth
                 id="highlights"
                 label="Highlights separate the bullet points using a ;"
                 value={highlights}
                 onChange={(e) => setHighlights(e.target.value)}

               />
             </Grid>
             <Grid item xs={12}>
               <TextField
                 autoComplete="longDesc"
                 name="longDesc"
                 variant="outlined"
                 fullWidth
                 id="longDesc"
                 label="Long Desc-add full para here"
                 value={longDesc}
                 onChange={(e) => setLongDesc(e.target.value)}
                 
               />
             </Grid>
           </Grid>
           <Button
             type="submit"
             fullWidth
             variant="contained"
             color="primary"
             className={classes.submit}
           >
             Create
           </Button>
         </form>
       </div>
     </Container>
   );
 }