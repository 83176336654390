import React, { useEffect, useState } from "react";
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import { Link } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
  container: {
    marginTop: theme.spacing(2),
  },
  paper: {
    padding: theme.spacing(2),
    color: theme.palette.text.secondary,
  },
}));

export default function OpportunitiesList() {
  const classes = useStyles();

  const [opportunities, setOpportunities] = useState([]);
  useEffect(() => {
    OpportunitiesGet()
  }, [])

  const OpportunitiesGet = () => {
    fetch("https://api.salter.in/opportunity/all",{
     headers: {
        'Content-Type': 'application/json',
     }})
      .then(res => res.json())
      .then(
        (result) => {
          setOpportunities(result)
        }
      )
  }

  const UpdateOpportunity = id => {
    window.location = '/opportunity/update/'+id
  }

  const OpportunityDelete = id => {
    var data = {
      'id': id
    }
    fetch('https://api.salter.in/opportunity/' + id, {
      method: 'DELETE',
    })
    .then(
      (result) => {
        alert('successfully deleted the opportunity');
        OpportunitiesGet();
      },
      (error) => {
        alert('could not delete opportunity with id' + id);
        OpportunitiesGet();
      }
    )
  }

  return (
    <div name="test" className={classes.root}>
      <Container className={classes.container} maxWidth="lg">
        <Paper className={classes.paper}>
          <Box display="flex">
            <Box flexGrow={1}>
              <Typography component="h2" variant="h6" color="primary" gutterBottom>
                Opportunities
              </Typography>
            </Box>
            <Box>
              <Link to="/opportunity/create">
                <Button variant="contained" color="primary">
                  Create New
                </Button>
              </Link>
            </Box>
          </Box>
          <TableContainer component={Paper}>
          <Table className={classes.table} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell align="right">Title</TableCell>
                <TableCell align="right">Code</TableCell>
                <TableCell align="center">Partner</TableCell>
                <TableCell align="left">Short Desc</TableCell>
                <TableCell align="left">Priority Order</TableCell>
                <TableCell align="left">Tags</TableCell>
                <TableCell align="left">Asset Class</TableCell>
                <TableCell align="left">Category</TableCell>
                <TableCell align="center">State</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {opportunities.map((opportunity) => (
                <TableRow key={opportunity.opportunityId}>
                  <TableCell align="right">{opportunity.opportunityTitle}</TableCell>
                  <TableCell align="right">{opportunity.opportunityCode}</TableCell>
                  <TableCell align="center">{opportunity.partnerId}</TableCell>
                  <TableCell align="left">{opportunity.opportunityDesc}</TableCell>
                  <TableCell align="left">{opportunity.priorityOrder}</TableCell>
                  <TableCell align="left">{opportunity.opportunityTagsCsv}</TableCell>
                  <TableCell align="left">{opportunity.assetClass}</TableCell>
                  <TableCell align="left">{opportunity.category}</TableCell>
                  <TableCell align="center">{opportunity.state}</TableCell>
                  <TableCell align="center">
                    <ButtonGroup color="primary" aria-label="outlined primary button group">
                      <Button onClick={() => UpdateOpportunity(opportunity.opportunityId)}>Edit</Button>
                      <Button onClick={() => OpportunityDelete(opportunity.opportunityId)}>Del</Button>
                    </ButtonGroup>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        </Paper>
      </Container>
    </div>

  );
}