import React, { useState, useEffect } from "react";
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Moment from 'react-moment';
import moment from 'moment';
import Container from '@material-ui/core/Container';
import { useParams } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

export default function PartnerUpdate() {
  const classes = useStyles();

  const [partner, setPartner] = useState('');

  const { id } = useParams();
  useEffect(() => {
    fetch("https://api.salter.in/partner/"+id)
      .then(res => res.json())
      .then(
        (result) => {
        setPartnerId(result.partnerId);
        setName(result.name);
        setLogoSrc(result.logoImgSrc);
        setDesc(result.partnerDesc);
        setMinInvest(result.keyAttributes.Min_invest);
        setPlatFees(result.keyAttributes.plat_fees);
        setAum(result.keyAttributes.aum);
        setAvgOfferSize(result.keyAttributes.avg_offer_size);
        setNoOfInvestor(result.keyAttributes.num_investors);
        setNoOfDeals(result.keyAttributes.num_deals);
        setCreatedOn(result.createdOnTimestamp);
        setLastUpdatedOn(result.lastUpdatedTimeStamp);
        }
      )
  }, [id])

  const handleSubmit = event => {
   event.preventDefault();
      var data = {
        'partnerId': partnerId,
        'name': name,
        'partnerDesc': desc,
        'logoImgSrc': logo_src,
        'keyAttributes' : {
                    'Min_invest' : minInvest,
                    'plat_fees' : platFees,
                    'aum' : aum,
                    'avg_offer_size' : avgOfferSize,
                    'num_investors' : noOfInvestor,
                    'num_deals' : noOfDeals,
         },
      }
      fetch('https://api.salter.in/partner/update', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      })
      .then(res => res.json())
      .then(
        (result) => {
           alert('Update Successful');
           window.location.href = '/';
        },
        (error) => {
         alert('Failed to make the update, please contact Admin');
         window.location.href = '/';
        }
      )
    }

  const [partnerId, setPartnerId] = useState('');
  const [name, setName] = useState('');
  const [desc, setDesc] = useState('');
  const [logo_src, setLogoSrc] = useState('');
  const [minInvest, setMinInvest] = useState('');
  const [platFees, setPlatFees] = useState('');
  const [aum, setAum] = useState('');
  const [avgOfferSize, setAvgOfferSize] = useState('');
  const [noOfInvestor, setNoOfInvestor] = useState('');
  const [noOfDeals, setNoOfDeals] = useState('');
  const [createdOn, setCreatedOn] = useState('');
  const [lastUpdatedOn, setLastUpdatedOn] = useState('');


  return (
    <Container maxWidth="md">
      <div className={classes.paper}>
        <Typography component="h1" variant="h5">
          Partner Details (Update)
        </Typography>
        <form className={classes.form} onSubmit={handleSubmit}>
          <Grid container spacing={2}>
          <Grid item xs={6}>
              <TextField
                autoComplete="partnerId"
                name="partnerId"
                variant="outlined"
                required
                fullWidth
                inputProps={{ readOnly: true, }}
                id="partnerId"
                label="partnerId"
                value={partnerId}
                onChange={(e) => setPartnerId(e.target.value)}
                autoFocus
              />
            </Grid>
          <Grid item xs={6}>
              <TextField
                autoComplete="name"
                name="name"
                variant="outlined"
                required
                fullWidth
                id="name"
                label="name"
                value={name}
                onChange={(e) => setName(e.target.value)}
                
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                autoComplete="desc"
                name="desc"
                variant="outlined"
                required
                fullWidth
                id="desc"
                label="desc"
                value={desc}
                onChange={(e) => setDesc(e.target.value)}
                
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                autoComplete="logo_src"
                name="logo_src"
                variant="outlined"
                required
                fullWidth
                id="logo_src"
                label="logo_src"
                value={logo_src}
                onChange={(e) => setLogoSrc(e.target.value)}
                
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                autoComplete="createdOn"
                name="createdOn"
                variant="outlined"
                inputProps={{ readOnly: true, }}
                required
                fullWidth
                id="createdOn"
                label="createdOn"
                value={moment(createdOn).format()}
                onChange={(e) => setCreatedOn(e.target.value)}
                
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                autoComplete="lastUpdatedOn"
                name="lastUpdatedOn"
                variant="outlined"
                inputProps={{ readOnly: true, }}
                required
                fullWidth
                id="lastUpdatedOn"
                label="lastUpdatedOn"
                value={moment(lastUpdatedOn).format()}
                onChange={(e) => setLastUpdatedOn(e.target.value)}
                
              />
            </Grid>
            <Grid item xs={12}>
            <Typography component="h5" variant="h5">
                Key Attributes
             </Typography>
            </Grid>
            <Grid item xs={4}>
               <TextField
                 autoComplete="minInvest"
                 name="minInvest"
                 variant="outlined"
                 fullWidth
                 id="minInvest"
                 label="Minimum Investment "
                 value={minInvest}
                 onChange={(e) => setMinInvest(e.target.value)}

               />
             </Grid>
             <Grid item xs={4}>
               <TextField
                 autoComplete="platform fees"
                 name="platFees"
                 variant="outlined"
                 fullWidth
                 id="platFees"
                 label="platform fees"
                 value={platFees}
                 onChange={(e) => setPlatFees(e.target.value)}

               />
             </Grid>
             <Grid item xs={4}>
               <TextField
                 autoComplete="aum"
                 name="aum"
                 variant="outlined"
                 fullWidth
                 id="aum"
                 label="Assets Under Management"
                 value={aum}
                 onChange={(e) => setAum(e.target.value)}

               />
             </Grid>
             <Grid item xs={4}>
               <TextField
                 autoComplete="avgOfferSize"
                 name="avgOfferSize"
                 variant="outlined"
                 fullWidth
                 id="avgOfferSize"
                 label="Average Offer Size"
                 value={avgOfferSize}
                 onChange={(e) => setAvgOfferSize(e.target.value)}

               />
             </Grid>
             <Grid item xs={4}>
               <TextField
                 autoComplete="noOfInvestor"
                 name="noOfInvestor"
                 variant="outlined"
                 fullWidth
                 id="noOfInvestor"
                 label="Number of Investors"
                 value={noOfInvestor}
                 onChange={(e) => setNoOfInvestor(e.target.value)}

               />
             </Grid>
            <Grid item xs={4}>
               <TextField
                 autoComplete="noOfDeals"
                 name="noOfDeals"
                 variant="outlined"
                 fullWidth
                 id="noOfDeals"
                 label="Number of Deals Closed Till Date"
                 value={noOfDeals}
                 onChange={(e) => setNoOfDeals(e.target.value)}
               />
             </Grid>
          </Grid>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
          >
            Update
          </Button>
        </form>
      </div>
    </Container>
  );
}