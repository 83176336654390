import React, { useEffect, useState } from "react";
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import Avatar from '@material-ui/core/Avatar';
import { Link } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
  container: {
    marginTop: theme.spacing(2),
  },
  paper: {
    padding: theme.spacing(2),
    color: theme.palette.text.secondary,
  },
}));

export default function PartnerList() {
  const classes = useStyles();

  const [partners, setPartners] = useState([]);
  useEffect(() => {
    PartnersGet()
  }, [])

  const PartnersGet = () => {
    fetch("https://api.salter.in/partner/all",{
     headers: {
        'Content-Type': 'application/json',
     }})
      .then(res => res.json())
      .then(
        (result) => {
          setPartners(result)
        }
      )
  }

  const UpdatePartner = id => {
    window.location = '/partner/update/'+id
  }

  const PartnerDelete = id => {
    var data = {
      'id': id
    }
    fetch('https://api.salter.in/partner/' + id, {
      method: 'DELETE',
    })
    .then(
      (result) => {
        alert('successfully deleted the partner');
        PartnersGet();
      },
      (error) => {
        alert('could not delete partner with id' + id);
        PartnersGet();
      }
    )
  }

  return (
    <div name="test" className={classes.root}>
      <Container className={classes.container} maxWidth="lg">
        <Paper className={classes.paper}>
          <Box display="flex">
            <Box flexGrow={1}>
              <Typography component="h2" variant="h6" color="primary" gutterBottom>
                Partners
              </Typography>
            </Box>
            <Box>
              <Link to="/partner/create">
                <Button variant="contained" color="primary">
                  Create New
                </Button>
              </Link>
            </Box>
          </Box>
          <TableContainer component={Paper}>
          <Table className={classes.table} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell align="left">Logo</TableCell>
                <TableCell align="left">Id</TableCell>
                <TableCell align="left">Partner Name</TableCell>
                <TableCell align="left">Partner Desc</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {partners.map((partner) => (
                <TableRow key={partner.partnerId}>
                  <TableCell align="left">
                    <Box display="flex" justifyContent="center">
                      <Avatar src={partner.logoImgSrc} />
                    </Box>
                  </TableCell>
                  <TableCell align="left">{partner.partnerId}</TableCell>
                  <TableCell align="left">{partner.name}</TableCell>
                  <TableCell align="left">{partner.partnerDesc}</TableCell>
                  <TableCell align="center">
                    <ButtonGroup color="primary" aria-label="outlined primary button group">
                      <Button onClick={() => UpdatePartner(partner.partnerId)}>Edit</Button>
                      <Button onClick={() => PartnerDelete(partner.partnerId)}>Del</Button>
                    </ButtonGroup>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        </Paper>
      </Container>
    </div>

  );
}